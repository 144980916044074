

import React, { PureComponent } from 'react'

export default class OverviewFolder extends PureComponent {
    render() {
        return (
            <div>
                Hello
            </div>
        )
    }
}
