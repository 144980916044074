



const licenses = {
    REQUIRE_ATTRIBUTION: 0,
    MIT: 1,
};


export default licenses;